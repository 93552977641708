var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"division",class:("division--" + _vm.division_shortname)},[_c('alerts',{attrs:{"section-title":"Alerts"}}),_c('slideshow',{attrs:{"slideshow-id":_vm.slideshow.hero,"template":"SlideshowHeroSlider","section-title":"Featured Slider","count":5}}),_c('div',{staticClass:"row max-width-grid padding-12 bp-1024:padding-t-72 bp-1024:padding-b-40 bp-1440:padding-x-0"},[_c('slideshow',{staticClass:"col-1-of-1 bp-1024:padding-r-24 bp-1024:col-2-of-3",attrs:{"slideshow-id":_vm.slideshow.feature,"template":"SlideshowFeature","section-title":"Featured Slideshow","count":1}}),_c('stories',{staticClass:"col-1-of-1 padding-t-12 bp-1024:padding-t-0 bp-1024:padding-l-24 bp-1024:col-1-of-3",attrs:{"template":"StoriesList","section-title":"Stories List","sport_id":"2","mode":"waterfall","division_id":_vm.division_id,"breakpoints":{
				0: { count: 4, skip: 0, template: 'StoriesList' },
				1024: { count: 7, skip: 0, template: 'StoriesList' },
			}}})],1),_c('div',{staticClass:"bg-color-surface border-y padding-x-12 padding-y-24\n\t\t\tbp-768:padding-24\n\t\t\tbp-1024:padding-y-48\n\t\t\tbp-1440:padding-y-72"},[_c('youtube',{staticClass:"row display-block max-width-grid",attrs:{"template":"YoutubeFeatured","section-title":"Youtube","breakpoints":{
				0: { count: 4, skip: 0, template: 'YoutubeFeatured' },
				1024: { count: 7, skip: 0, template: 'YoutubeFeatured' },
			},"division_id":_vm.division_id}})],1),_c('slideshow',{staticClass:"row display-block max-width-grid padding-12 bp-1024:padding-y-72 bp-1440:padding-x-0",attrs:{"slideshow-id":_vm.slideshow.cards,"template":"SlideshowCardSlider","section-title":"Featured"}}),_c('slideshow',{attrs:{"slideshow-id":_vm.slideshow.panels,"template":"SlideshowPanels","section-title":(_vm.division_name + " Priorities"),"show-section-title":false,"count":3,"hide-title":""}}),_c('div',{staticClass:"bg-color-surface padding-x-12\n\t\t\tbp-768:padding-x-24"},[_c('slideshow',{staticClass:"row display-block max-width-grid border-y padding-y-12\n\t\t\t\tbp-768:padding-y-24\n\t\t\t\tbp-1024:padding-y-48\n\t\t\t\tbp-1440:padding-y-72",attrs:{"slideshow-id":_vm.slideshow.linklist,"template":"SlideshowLinkList","section-title":"Link Lists"}})],1),_c('content-stream',{attrs:{"template":"ContentStreamPackery","section-title":("Connect With " + _vm.division_name),"division_id":_vm.division_id,"breakpoints":{
			0: { count: 4, skip: 0, template: 'ContentStreamPackery' },
			1024: { count: 10, skip: 0, template: 'ContentStreamPackery' },
		}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }