<template>
	<main class="division" :class="`division--${division_shortname}`">
		<alerts section-title="Alerts" />
		<slideshow
			:slideshow-id="slideshow.hero"
			template="SlideshowHeroSlider"
			section-title="Featured Slider"
			:count="5"
		/>
		<div
			class="row max-width-grid padding-12 bp-1024:padding-t-72 bp-1024:padding-b-40 bp-1440:padding-x-0"
		>
			<slideshow
				:slideshow-id="slideshow.feature"
				template="SlideshowFeature"
				section-title="Featured Slideshow"
				:count="1"
				class="col-1-of-1 bp-1024:padding-r-24 bp-1024:col-2-of-3"
			/>
			<stories
				class="col-1-of-1 padding-t-12 bp-1024:padding-t-0 bp-1024:padding-l-24 bp-1024:col-1-of-3"
				template="StoriesList"
				section-title="Stories List"
				sport_id="2"
				mode="waterfall"
				:division_id="division_id"
				:breakpoints="{
					0: { count: 4, skip: 0, template: 'StoriesList' },
					1024: { count: 7, skip: 0, template: 'StoriesList' },
				}"
			/>
		</div>
		<div
			class="bg-color-surface border-y padding-x-12 padding-y-24
				bp-768:padding-24
				bp-1024:padding-y-48
				bp-1440:padding-y-72"
		>
			<youtube
				class="row display-block max-width-grid"
				template="YoutubeFeatured"
				section-title="Youtube"
				:breakpoints="{
					0: { count: 4, skip: 0, template: 'YoutubeFeatured' },
					1024: { count: 7, skip: 0, template: 'YoutubeFeatured' },
				}"
				:division_id="division_id"
			/>
		</div>
		<slideshow
			class="row display-block max-width-grid padding-12 bp-1024:padding-y-72 bp-1440:padding-x-0"
			:slideshow-id="slideshow.cards"
			template="SlideshowCardSlider"
			section-title="Featured"
		/>
		<slideshow
			:slideshow-id="slideshow.panels"
			template="SlideshowPanels"
			:section-title="`${division_name} Priorities`"
			:show-section-title="false"
			:count="3"
			hide-title
		/>
		<div
			class="bg-color-surface padding-x-12
				bp-768:padding-x-24"
		>
			<slideshow
				class="row display-block max-width-grid border-y padding-y-12
					bp-768:padding-y-24
					bp-1024:padding-y-48
					bp-1440:padding-y-72"
				:slideshow-id="slideshow.linklist"
				template="SlideshowLinkList"
				section-title="Link Lists"
			/>
		</div>
		<content-stream
			template="ContentStreamPackery"
			:section-title="`Connect With ${division_name}`"
			:division_id="division_id"
			:breakpoints="{
				0: { count: 4, skip: 0, template: 'ContentStreamPackery' },
				1024: { count: 10, skip: 0, template: 'ContentStreamPackery' },
			}"
		></content-stream>
	</main>
</template>

<script>
import Alerts from '@/components/Alerts';
import Slideshow from '@/components/slideshow/Slideshow';
import Stories from '@/components/stories/Stories';
import Youtube from '@/components/youtube/Youtube';
import ContentStream from '@/components/contentstream/ContentStream';

export default {
	name: 'Division',

	components: {
		Alerts,
		Slideshow,
		Stories,
		Youtube,
		ContentStream,
	},

	computed: {
		division_name: function() {
			return this.$route.name;
		},

		division_shortname: function() {
			return this.$route.path.replace('/', '');
		},

		division_id: function() {
			return this.$route.meta.division_id;
		},

		slideshow: function() {
			return {
				hero: this.$route.meta.slideshow_hero,
				feature: this.$route.meta.slideshow_feature,
				cards: this.$route.meta.slideshow_cards,
				panels: this.$route.meta.slideshow_panels,
				linklist: this.$route.meta.slideshow_linklist,
			};
		},
	},

	created() {
		this.setMetaSidearm(this.division_name, [
			{
				name: 'og:title',
				content: this.division_name,
			},
			{
				name: 'twitter:title',
				content: this.division_name,
			},
		]);
	},

	watch: {
		$route(to, from) {
			if (to.name != from.name) {
				this.setMetaSidearm(this.division_name, [
					{
						name: 'og:title',
						content: this.division_name,
					},
					{
						name: 'twitter:title',
						content: this.division_name,
					},
				]);
			}
		},
	},
};
</script>
