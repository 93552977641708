<template>
	<div
		class="component c-alerts position-relative margin-x-12 bp-768:padding-12 bp-768:margin-x-0 bp-1024:padding-24"
		v-if="data && data.length"
	>
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<div class="row display-block max-width-grid">
			<button
				@click="toggle_list"
				class="c-alerts__toggle reset bg-color-white text-brand font-size-24px cursor-pointer border-radius-rounded flex-center position-absolute right-0 top-0 z-index-3
					bp-768:display-none"
				ref="alerts_toggle"
			>
				<span class="sf-megaphone sidearm-icon reset-icon"></span>
				<span
					class="c-alerts__toggle-count bg-color-danger text-white pointer-events-none utility-flex-center border-radius-rounded font-size-14 position-absolute top-0 right-0 z-index-2"
					>{{ data.length }}</span
				>
			</button>
			<ul
				class="c-alerts__list border reset-list width-100 margin-t-32 z-index-2
					bp-768:margin-t-0 bp-768:border-none"
				ref="alerts_list"
			>
				<template v-for="(item, index) in data">
					<li
						v-if="item.link"
						class="c-alerts__item --has-link bg-color-surface position-relative"
						:class="{ 'margin-t-12': index > 0 }"
						:key="item.id"
					>
						<navigation-link
							:url="item.link"
							_class="c-alerts__link width-100 display-flex align-items-center text-decoration-none padding-y-12 padding-r-24 padding-l-16 bp-768:padding-l-24"
							:_target="item.open_in_new_tab ? '_blank' : '_self'"
						>
							<span
								class="sf-megaphone display-none bp-768:display-block font-size-24px sidearm-icon reset-icon"
							></span>
							<span
								class="c-alerts__item-divider border display-none bp-768:display-block margin-x-24"
								style="height:36px;width:2px;"
							></span>
							<span class="flex-grow-1">{{ item.title }}</span>
							<arrow-right-circle-icon
								class="c-alerts__link-arrow margin-l-12 flex-shrink-0"
								size="24"
							/>
						</navigation-link>
					</li>
					<li
						v-else
						class="c-alerts__item display-flex align-items-center padding-y-12 padding-r-24 padding-l-16 bp-768:padding-l-0 bg-color-surface position-relative bp-768:padding-l-24"
						:class="{ 'bp-768:margin-t-12': index > 0 }"
						:key="item.id"
					>
						<span
							class="sf-megaphone display-none font-size-24px bp-768:display-block sidearm-icon reset-icon"
						></span>
						<span
							class="c-alerts__item-divider border display-none bp-768:display-block margin-x-24"
							style="height:36px;width:2px;"
						></span>
						<span>{{ item.title }}</span>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import NavigationLink from '@/components/Common/NavigationLink';
import { ArrowRightCircleIcon } from 'vue-feather-icons';

export default {
	name: 'Alerts',

	mixins: [baseComponent],

	components: {
		NavigationLink,
		ArrowRightCircleIcon,
	},

	methods: {
		toggle_list() {
			if (this.$refs.alerts_list.classList.contains('is-active')) {
				this.close_list();
			} else {
				this.open_list();
			}
		},

		open_list() {
			this.$refs.alerts_list.classList.add('is-active');
			this.$refs.alerts_toggle.classList.add('is-toggled');
		},

		close_list() {
			this.$refs.alerts_list.classList.remove('is-active');
			this.$refs.alerts_toggle.classList.remove('is-toggled');
		},
	},

	created() {
		let request_options = {
			type: 'alerts',
		};

		get(
			`${this.$root.proxy}/services/adaptive_components.ashx`,
			request_options,
		)
			.then(response => {
				this.original_data = response;
			})
			.catch(error => {
				console.log(error);
			});
	},
};
</script>

<style lang="scss" scoped>
.c-alerts {
	&__toggle {
		box-shadow: inset 0 0 0 1px var(--color-primary);
		height: 48px;
		width: 48px;
		transition: all 0.25s;
		top: 12px;

		&.is-toggled {
			background-color: var(--color-primary);
			color: var(--color-text-on-primary);
		}

		&-count {
			width: 20px;
			height: 20px;
			transform: translate(3px, -3px);
		}
	}

	&__item {
		font-size: 14px;
		min-height: 60px;

		&.--has-link {
			transition: box-shadow 0.25s;

			&:hover,
			&:focus {
				box-shadow: inset 0 0 0 2px var(--color-secondary);

				.c-alerts__link-arrow {
					transform: translateX(6px);
				}
			}
		}
	}

	&__link {
		min-height: 60px;

		.c-alerts__link-arrow {
			transition: transform 0.25s;
		}
	}

	@media screen and (max-width: 767px) {
		&__list {
			opacity: 0;
			transform: scale(0.8);
			transform-origin: top right;
			transition: transform 0.25s, opacity 0.25s, left 0s 0.25s;
			left: -99999px;
			position: absolute;

			&.is-active {
				left: 0;
				opacity: 1;
				transform: scale(1);
				transition: transform 0.25s, opacity 0.25s;
			}
		}

		&__item + .c-alerts__item {
			border-top: 1px solid var(--color-default-theme-stroke);
		}
	}

	@media screen and (min-width: 768px) {
		&__item {
			box-shadow: inset 0 0 0 1px var(--color-default-theme-stroke);
		}
	}
}
</style>
